// eslint-disable-next-line no-unused-vars
import {ref} from 'vue'
import {getNumber} from "@utils/string";

// eslint-disable-next-line no-unused-vars
let START = ["7", "8", "9"];

// eslint-disable-next-line no-unused-vars
let startString = [
    {
        value: '8',
        result: '8',
        default: '+7'
    }
];


// eslint-disable-next-line no-unused-vars
function getStartString() {
    return ''
}

export default function usePhoneMask(value) {
    let inputNumber = ref(getNumber(value));
    // let selectionStart = target.selectionStart;
    // if(selectionStart >){
    // }

    // if (START.includes(inputNumber.value[0])) {
    //     console.log(getStartString);
    //     return `+${inputNumber.value}`
    //     // console.log('ru');
    // } else {
    //     console.log('not ru');
    //     return `+${inputNumber.value}`
    // }
    // console.log(inputNumber.value);
    return `+ ${inputNumber.value}`
}
