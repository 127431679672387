/**
 * Плучение объекта в зависимости от разрешения экрана
 * @param responsive
 * @param window
 * @returns {boolean|*}
 */
export default function useResponse(responsive, window) {
    if (responsive) {
        let keys = Object
                .keys(responsive)
                .sort(function (a, b) {
                    return a - b
                }),
            match = -1;

        keys.forEach(item => {
            let value = parseInt(item);
            if (value <= window && value > match) {
                match = Number(value);
            }
        });

        return responsive[match];
    }

    return false;
}
