export default function useTemplate(strings, ...keys) {
    /** Теговый шаблон - добавляет переменные в заданую шаблонную строку
     *
     * Пример использования
     * let tpl = useTemplate`<div>${'text'}</div>`
     * tpl({text: 'Text in template'})
     *
     * let tpl = useTemplate`<div>${0}</div>`
     * tpl('Text in template')
     *
     * URL: https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Template_literals
     */

    return (function (...values) {
        let dict = values[values.length - 1] || {};
        let result = [strings[0]];
        keys.forEach(function (key, i) {
            let value = Number.isInteger(key) ? values[key] : dict[key];
            result.push(value, strings[i + 1]);
        });
        return result.join('');
    });
}
