<template>
    <div class="preloader"></div>
</template>

<script>
    export default {
        name: "Preloader",
        setup(){
            return {}
        }
    }
</script>

<style></style>
